import React from 'react';
import TwinklingDot from '../common/TwinklingDot';
import { shortNameBackground, displayShortName } from '../../helpers/globals';
import { dateFormat, formatHours, formatNumber } from '../../helpers/stringFormat';
import Dots from '../Dots';
import Avatar from '../Avatar';
import { Tooltip } from 'antd'

const Card = (props) => {
    return (
        <div className='job-card'>
            <div className='card-color' style={{ backgroundColor: `${props?.colorList?.data?.find(data => { return data?.id == props?.data?.color_id }) != undefined ? props?.colorList?.data?.find(data => { return data?.id == props?.data?.color_id })['color'] : ''}` }} />
            <div className='w-100'>
                <b className="card-number">
                    #{props.data.ro_number}
                </b>
                <span className='card-extra-small-text'>{props?.data?.in_date || props.data.out_date ? `${props?.data?.in_date ? dateFormat(props?.data?.in_date, 'INOUT_DATE') : '-'} / ${props.data.out_date ? dateFormat(props.data.out_date, 'INOUT_DATE') : '-'}` : ''}</span>
                <div className='card-name'>
                    <p className='card-name-value'>{props.data.customer != null ? props.data.customer.firstname : ''} {props.data.customer != null ? props.data.customer.lastname : ''}</p>
                    <p className='sub-text'>{props.data.vehicle?.year} {props.data.vehicle?.make?.name} {props.data.vehicle?.model}</p>
                    <p className='sub-text'>{props.data?.insurance_company}</p>
                </div>
                <div className='content-bottom'>
                    <div className='d-flex justify-content-between w-100 card-value'>
                        <Tooltip
                            title={`Frame hours: ${props.data.frame_hours ? parseFloat((props.data.frame_hours).toString()) + 'h' : '0h'}\nPaint hours: ${props.data.paint_hours ? parseFloat((props.data.paint_hours).toString()) + 'h' : '0h'}`}
                            mouseEnterDelay={0.5}
                            overlayStyle={{ whiteSpace: 'pre-line' }}
                        >
                            <span>{props.data.frame_hours ? formatHours(props.data.frame_hours, 'h') : '0h'} / {props.data.paint_hours ? formatHours(props.data.paint_hours, 'h') : '0h'}</span>
                        </Tooltip>
                        <span>{props?.data?.value ? `${formatNumber(props.data.value, 2, '$')}` : ''}</span>
                    </div>
                    <div className='dots-container'>
                        <Dots defaultValue={props?.data?.dots_bits ? Array.from(props.data.dots_bits) : []} jobData={props.data} isFilter={false} />
                    </div>
                    {(props?.data?.assigned_to_user || props?.data?.has_unread_notes > 0 || props?.data?.has_files > 0) &&
                        <div className='card-footer'>
                            <div className='section-card'>
                                {props?.data?.assigned_to_user &&
                                    <Avatar
                                        userImage={props?.data?.assigned_to_user?.avatar}
                                        userName={props?.data?.assigned_to_user}
                                        backgroundColor={`${props.colorList.data.find(data => { return data.id == props.data.color_id }) != undefined ? props.colorList.data.find(data => { return data.id == props.data.color_id })['color'] : ''}`}
                                    />
                                }
                            </div>
                            {(props.data?.has_unread_notes > 0 || props.data.has_files > 0) && <div className='divider' />}

                            <div className='section-card'>
                                {props.data?.has_unread_notes > 0 &&
                                    <>
                                        <img src={require('../../assets/images/email.svg').default} />
                                    </>
                                }
                            </div>
                            {props.data.has_files > 0 && <div className='divider' />}

                            <div className='section-card'>
                                {props.data.has_files > 0 &&
                                    <>
                                        <img src={require('../../assets/images/attachment.svg').default} />
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Card;